import React, { useEffect, useState } from 'react';
import InputNumber from 'react-input-number';

import { CgTrash } from 'react-icons/cg';
import { AiOutlineHeart } from 'react-icons/ai';

const quantityValues = [1,2,3,4,5,6,7,8,9,10];

function Item(props){
    const [id] = useState(() => {
        return props.item.id;
    })
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [extras, setExtras] = useState([]);
    const [moneyType, setMoneyType] = useState(props.moneyType);

    const responsive = props.responsive;

    useEffect(() => {
        setName(props.item.name);
        setPrice(props.item.price);
        setMoneyType(props.moneyType);
        setQuantity(props.item.quantity);
        

        if (props.item.extra && props.item.extra.length > 0) {
            setExtras(props.item.extra);
        }
    }, [props.item, props.moneyType]);

    const handleSetQuantity = ((e) => {
        props.action(id,(e - quantity))
        setQuantity(e);
    })

    const handleClick = ((e) => {
        if(! (quantity + e > 100 || quantity + e < 1)){
            props.action(id,e)
            setQuantity(quantity + e);
        }
        
    })

    const removeItem = (() => {
        props.action(id, -quantity);
        props.remove(props.item.id);
    })

    document.addEventListener('click', function (event) {
        const valueDropDown = document.getElementsByClassName(`dropDownQuantity ${id}`)[0];
        const valueLabel = document.getElementsByClassName('dropDownSelect ' + id)[0];

        if (valueDropDown) {
            const selector = document.getElementsByClassName(`dropDownSelect ${id}`)[0];

            if (!valueLabel.contains(event.target)) {
                valueDropDown.style.display = 'none';
                selector.style["border-bottom-left-radius"] = '5px';
                selector.style["border-bottom-right-radius"] = '5px';
            }
        }
    });

    return(
        <div className='item-container'>
            <div className='image'>
                <img alt="Product" src={require(`../assets/${props.item.img}`).default} width={100}/>
            </div>
            <div className='data'>
                <div className='info'>
                    <div className='description'>
                        <span className='name'> {name} </span>
                        {
                            extras.map((val) => {
                                const key = Object.keys(val);
                                return <div key={key} className='extra'>
                                        <span className='key'>{key}:</span> 
                                        <span> {val[key]} </span>
                                       </div>
                            })   
                        }
                    </div>
                    <span className='price'>
                        {`${moneyType} ${price.toFixed(2)}`}
                    </span>
                </div>
                <div className='actions'>
                    {responsive.mobile ?
                        <div className='wish-mobile'>
                            <button><AiOutlineHeart size={"90%"}/></button>
                        </div>
                     :
                        <div className='buttons'>
                            <button onClick={removeItem}>Remove</button>
                            <button>Move to wishlist</button>
                        </div>
                    }
                    
                        {responsive.mobile ?
                            <div className='quantity'>
                                {quantity === 1 ?
                                    <button className='remove-mobile' onClick={removeItem}><CgTrash size={"80%"}/></button>
                                :
                                    <button className="change-quantity left" onClick={() => handleClick(-1)}>-</button>
                                }
                                <InputNumber className='input' value={quantity} onChange={handleSetQuantity} />
                                <button className="change-quantity right" onClick={() => handleClick(+1)}>+</button>
                            </div>
                            
                        : 
                        <div className={'quantity drop ' + id}>
                            <div className={'dropDownSelect ' + id} onClick={() => {
                                const selector = document.getElementsByClassName(`dropDownSelect ${id}`)[0];
                                const dropdown = document.getElementsByClassName(`dropDownQuantity ${id}`)[0];
                                if(dropdown.style.display === 'block'){
                                    dropdown.style.display = 'none';
                                    selector.style["border-bottom-left-radius"] = '5px';
                                    selector.style["border-bottom-right-radius"] = '5px';
                                }
                                else{
                                    selector.style["border-bottom-left-radius"] = '0';
                                    selector.style["border-bottom-right-radius"] = '0';
                                    dropdown.style.display = 'block'
                                }
                            }}>
                                {quantity}
                            </div>
                            <ul className={`dropDownQuantity ${id}`} >
                                {quantityValues.map((value) => 
                                    <li key={value} value={value} onClick={(e) => handleSetQuantity(e.target.value)}>
                                        {value}
                                    </li>
                                )}
                            </ul>

                        </div>

                        }
                </div>
            </div>
        </div>
    )
}

export default Item;
