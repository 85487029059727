import React, { useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import { useParams } from "react-router-dom";

function Checkout(){
    const [coin] = useState(() => 
        JSON.parse(localStorage.getItem('coin'))
    );
    const [total] = useState(() => {
        let total = JSON.parse(localStorage.getItem("total"))
        return total || 0;
    });
    const [shipping] = useState(() => {
        let ship = JSON.parse(localStorage.getItem("shipping"))
        return ship || 0;
    });
    const [selectedItems] = useState(() => {
        let items = JSON.parse(localStorage.getItem("selectedItems"))
        return items || [];
    });
    const [width, setWindowWidth] = useState(0);

    const responsive = {
        mobile: width < 1023,
        class: width < 1023 ? 'mobile' : 'pc'
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
    },[]);
    
    return (
        <div className='container'>
            <NavBar
                placement={'end'}
                menu={false}
            />
            <div className='data-container'>
                <h1>Payment</h1>
                <div className={'data '+ responsive.class}>
                    <div className='payment'>
                        <imburse-checkout token={useParams().sessionToken} return-url={`${window.location.origin.toString()}/verify/`} mode="sandbox" />
                    </div>
                    <div className='resume'>
                        <div className='details'>
                            <div className='summary'> 
                                <h3>Order summary</h3>
                                {selectedItems.map((val) => {
                                    return (
                                        <div className='item-resume'>
                                            <span className='qnt'>{val.quantity}x</span>
                                            <span className='name'><b>{val.name}</b></span>
                                            <span className='price-resume'>{coin} {val.price.toFixed(2)}</span>    
                                        </div>
                                    )
                                })}
                            </div> 
                            <div className='customer'> 
                                <h3>Customer details</h3>
                                <span>Alicia Schmidt</span>
                                <span>alishc@gmail.com</span>                            
                            </div>
                            <div className='subtotal'> 
                                    <span>Subtotal</span>
                                    <span>{coin} {total.toFixed(2)}</span>                            
                            </div>
                            <div className='shipping'> 
                                <span>Estimated Shipping</span>
                                <span>{coin} {shipping.toFixed(2)}</span>                            
                            </div>
                            <div className='total'> 
                                <span>Total</span>
                                <span>{coin} {(total+shipping).toFixed(2)}</span>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;
