import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.svg';
import userImg from "../assets/user.svg";
import * as API from '../middleware/api';
import ReactFlagsSelect from 'react-flags-select';
import LogoutButton from './logout-button';
import { Nav } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';

const NavBar = (props) => {
    const [schemas, setSchemas] = useState([]);
    const [selected, setSelected] = useState('DE');
    const [currentCoin, setCurrentCoin] = useState('EUR / €');
    const [currentScheme, setCurrentScheme] = useState({name: 'No schemas available'});
    const { user } = useAuth0();
    const { name, email } = user;

    const createBackground = () => {
        const container = document.getElementsByClassName('container')[0];

        const newDiv = document.createElement('div');
        newDiv.id = 'backgroundContainer';
        newDiv.display = 'block';
        newDiv.classList.toggle('active');

        container.appendChild(newDiv);
    }

    const buttonHandler = () => {
        document.getElementById('popup').classList.toggle('active');
        document.getElementById('navbar-button').classList.toggle('active'); 
        document.getElementById('logo').classList.toggle('active'); 

        const backgroundContainer = document.getElementById('backgroundContainer');

        if (backgroundContainer) {
            backgroundContainer.classList.toggle('active'); 
            return;
        }

        createBackground();
    }

    const schemeInArray = (scheme, array) => {
        return array.findIndex((v) =>
            (v.name === scheme.name && v.id === scheme.id)
        ) !== -1;
    }

    useEffect(() => {
        if (props.menu) {
            API.getPayoutSchemes().then((res) => {
                if (res.length) {
                    const { setScheme } = props;
                    if (!currentScheme.id || !schemeInArray(currentScheme, res)) {
                        setSchemas(res);
                        setCurrentScheme(res[0]);
                        setScheme(res[0].id);
                    }
                }
            });
        }
        
    }, [props, currentScheme]);

    const changeCountry = (code) => {
        const { setCountry } = props;
        setCountry(code);
        setSelected(code);
    }

    const changeCoin = (e) => {
        const { setCoin } = props;
        setCoin(e.target.innerText);
        setCurrentCoin(e.target.innerText);
        document.getElementsByClassName('customDropDown')[0].style.display = 'none';
    }

    const changeScheme = (e) => {
        const { setScheme } = props;
        setScheme(e.target.getAttribute('scheme-index'));
        setCurrentScheme({
            name: e.target.innerText,
            id: e.target.getAttribute('scheme-index')
        });
    }

    const coins = ['CHF / CHF', 'CZK / Kč', 'BGN / лв', 'EUR / €', 'HUF / Ft'];

    document.addEventListener('click', function (event) {
        const btn = document.getElementById('navbar-button');
        const menu = document.getElementById('popup');
        const coinDropDown = document.getElementsByClassName('customDropDown')[0];
        const coinLabel = document.getElementsByClassName('customLabel')[0];
        const schemeDropDown = document.getElementsByClassName('customDropDown')[1];
        const schemeLabel = document.getElementsByClassName('customLabel')[1];

        if (menu) {
            if (!menu.contains(event.target) && !btn.contains(event.target)) {
                menu.classList.remove('active');
                btn.classList.remove('active');
                document.getElementById('logo').classList.remove('active');
                const backgroundContainer = document.getElementById('backgroundContainer');
                if (backgroundContainer) {
                    backgroundContainer.classList.remove('active'); 
                }
            }
        }

        if (coinDropDown) {
            if (!coinLabel.contains(event.target)) {
                coinDropDown.style.display = 'none';
            }
        }

        if (schemeDropDown) {
            if (!schemeLabel.contains(event.target)) {
                schemeDropDown.style.display = 'none';
            }
        }
    });

    return (
        <>
            <header id="navbar">
                <img id='logo' src={logo} alt='logo' />
                {props.menu && <button id='navbar-button' onClick={buttonHandler} />}
            </header>
            {
                (props.menu) &&
                <div id='popup'>
                    <div id='profile'>
                        <div id='profileImage'>
                            <img src={userImg} alt='profile' />
                        </div>
                        <div id='info'>
                            <div className='header'>{name}</div>
                            <div id='email'>{email}</div>
                            <Nav className="justify-content-end">
                                <LogoutButton />
                            </Nav>
                        </div>
                    </div>
                    <div id='horizontalLine'></div>
                    <div id='selectors'>
                        <div className='header'>Settings</div>
                        <div className='selectLabel'>Country</div>
                        <ReactFlagsSelect id='countries'
                            selected={selected}
                            onSelect={code => changeCountry(code)}
                            countries={["DE", "GB", "CH", "PT", "US", "ZA", "ES"]}
                            customLabels={{
                                "DE": "Deutchland",
                                "GB": "United Kingdom",
                                "CH": "Switzerland",
                                "PT": "Portugal",
                                "US": "United States",
                                "ZA": "South Africa",
                                "ES": "Spain"
                            }}
                            placeholder="Select country"
                        />

                        <div className='selectLabel'>Currency</div>
                        <div id='coin'>
                            <div className='customLabel' onClick={() => {
                                const dropdown = document.getElementsByClassName('customDropDown')[0];
                                dropdown.style.display = (dropdown.style.display === 'block') ? 'none' : 'block';
                            }}>
                                {currentCoin}
                            </div>
                            <ul className='customDropDown'>
                                {coins.map((value) =>
                                    <li key={value} onClick={changeCoin}>
                                        {value}
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className='selectLabel'>Payment scheme</div>
                        <div id='schemas'>
                            <div className='customLabel' onClick={() => {
                                const dropdown = document.getElementsByClassName('customDropDown')[1];
                                dropdown.style.display = (dropdown.style.display === 'block') ? 'none' : 'block';
                            }}>
                                {currentScheme.name}
                            </div>
                            <ul className='customDropDown'>
                                {schemas.map((value) =>
                                    <li key={value.id} scheme-index={value.id} onClick={changeScheme}>
                                        {value.name}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <button id='shuffleButton' onClick={props.shuffleItems}>Shuffle shopping cart</button>
                </div>
            }
        </>
    );
}

export default NavBar;