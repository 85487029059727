const axios = require('axios');

//const apiUrl = "http://localhost:5000/imburse";

const apiUrl = process.env.REACT_APP_SERVER_ADDRESS + "/imburse";

export async function createSessionToken(data, callback){ 
	const url = apiUrl + '/createSessionToken';

	await axios.post(url, data)
		.then(async (res) => {
			callback(null,res);
		})
		.catch((err) => {
			callback(err);
		});
}

export async function createOrder(data, callback){ 
	const url = apiUrl + '/createOrder';
	
	await axios.post(url, data)
		.then(async (res) => {
			callback(null,res);
		})
		.catch((err) => {
			console.log(err.message)
			callback(err);
		});
}

export async function getPayoutSchemes() {
	const url = apiUrl + '/getSchemes';
	const schemes = [];
	await axios.get(url)
		.then((res) => {
			res.data.schemes.forEach((scheme) => {
				schemes.push({name: scheme.drafts[0].name, id: scheme.schemeId})
			});
		})
		.catch((error) => {
			console.log(error);
		});
	return schemes;
}