import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Item from '../components/Item';
import NavBar from '../components/navbar';
import * as API from '../middleware/api';
import Loading from '../components/loading';

const items = [
    {id: 1, name: 'Victrola 3-speed Bluetooth Portable Player', price: 44.90, img:'image1.png',quantity: 1, extra : [
        {Color: "Black"},
    ]},
    {id: 2, name: 'Power Bank with Tri-Outport & Dual Inport', price: 20, img:'image2.png',quantity: 1, extra: [
        {Color: "Black"},
        {Style: "38600 mAH"}
    ]},
    {id: 3, name: 'GE Wireless LED Puck Lights', price: 5.55,quantity: 1, img:'light.jpg', extra : [
        {Color: "White"},
        {Quantity: "2"},
        {Activated: "On/Off"}
    ]},
    {id: 4, name: 'Fujifilm Instax Mini 9 Instant Camera', price: 64.9,quantity: 1, img:'image4.png', extra : [
        {Color: "Red"}
    ]},
    {id: 5, name: 'Logitech C922x Pro Stream Webcam', price: 83.44,quantity: 1, img:'camera.jpg', extra : [
        {Style: "Full 1080p HD"},
        {Color: "Black"}
    ]},
    {id: 6, name: 'Raspberry Pi 4 Model B 2019 Quad Core 64 Bit WiFi Bluetooth', price: 162.13,quantity: 1, img:'raspberry.jpg'},
    {id: 7, name: 'Sabrent 4-Port USB 2.0 Data Hub with Individual LED lit Power Switches', price: 8.3,quantity: 1, img:'adapter.jpg',  extra : [
        {LED: "Blue"},
        {Color: "Black"}
    ]},
    {id: 8, name: 'Fujifilm Instax Mini 8', price: 34.9, img:'image5.png',quantity: 1, extra : [
        {Color: "White"}
    ]},
    {id: 9, name: 'Razer Enki Gaming Chair', price: 399.9,quantity: 1, img:'chair.jpg', extra : [
        {Color: "Black/Green"},
        {Material: "Leather"}
    ]},
    {id: 10, name: 'ASUS ZenBook Flip 13 OLED laptop', price: 1040.99 ,quantity: 1, img:'zenbook.jpg'}
]

function Cart(){
    let history = useHistory();
    const [total, setTotal] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [coin, setCoin] = useState('EUR / €');
    const [shipping, setShipping] = useState(0.73 * 4);
    const [loading, setLoading] = useState(false);
    const [scheme, setScheme] = useState('');
    const [country, setCountry] = useState('DE');
    const [errorMessage, setErrorMessage] = useState('');
    const [refreshState, setRefreshState] = useState(true);

    const [width, setWindowWidth] = useState(0);

    const responsive = {
        mobile: width < 1023,
        class: width < 1023 ? 'mobile' : 'pc'
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const refreshTotal = (id,e) => {
        for(let i = 0; i < selectedItems.length; i++ ){
            let item = selectedItems[i];
            if(item.id === id){
                selectedItems[i].quantity = item.quantity + e;

                const num = total + item.price*e;
                setTotal(Math.round(num * 100) / 100)
            }
        }
    }

    const removeItem = ((e) => {
        const valid = selectedItems.filter((val) => {
            return val.id !== e;
        })

        setSelectedItems(valid)

        setShipping(valid.length * 0.73)
    })

    const saveInfo = () => {
        localStorage.setItem("coin", JSON.stringify(coin.slice(coin.indexOf('/') + 1)));
        localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
        localStorage.setItem("shipping",JSON.stringify(shipping));
        localStorage.setItem("total",JSON.stringify(total));
    }

    const goToCheckout = (async () => {
        if (total === 0) {
            setErrorMessage('*Cart cannot be empty.');
            return;
        }
        if (scheme === '') {
            setErrorMessage('*There is no scheme selected.')
            return;
        }

        setLoading(true);
        saveInfo();

        const data = {
            orderRef: "Acme-" + Date.now(),
            instructionRef: "PAYMENT-" + Date.now(),
            customerRef: "AliciaSchmidt",
            direction:"DEBIT",
            amount: (total + shipping).toFixed(2),
            currency: coin.slice(0, coin.indexOf('/') - 1),
            country: country,
            schemeId: scheme,
            settledByDate: new Date().toISOString().slice(0, 10)
        }

        API.createOrder(data,(err,res) => {
            if(!err && res){
                const body = {
                    orderRef: data.orderRef,
                    instructionRef: data.instructionRef,
                    cardholderName: "Alicia Schmidt"
                }
    
                API.createSessionToken(body,(err,res) => {
                    if(!err && res){
                        history.push(`/checkout/${res.data.sessionToken}`)
                    }
                    else {
                        console.log(err);
                    }
                })
            }
            else{
                console.log(err);
            }
        })
    })

    const shuffleItems = () => {
        items.map((val) => {
            val.quantity = 1;
            return val.id;
        })
        setRefreshState(!refreshState);
        
        const validItems = items.sort(() => 0.5 - Math.random()).slice(0, 4);
    
        setTotal(validItems.reduce((curr,val) => {
            return curr + val.price;
        },0));

        setSelectedItems(validItems);
    }

    useEffect(() => {
        if(JSON.parse(localStorage.getItem("restart"))){
            localStorage.removeItem("restart")
            localStorage.removeItem("selectedItems")
            localStorage.removeItem("shipping")
            localStorage.removeItem("total")
            localStorage.removeItem("coin")
            setSelectedItems([]);
            setTotal(0);
            setShipping(0);
        }else{
            shuffleItems();
        }

        updateDimensions();

        window.addEventListener("resize", updateDimensions);
    },[]);

    return (
        loading ? 
            <Loading/>
        :
            <div className='container'>
                <NavBar
                    placement={'end'}
                    setCoin={setCoin}
                    setScheme={setScheme}
                    setCountry={setCountry}
                    shuffleItems={shuffleItems}
                    menu={true}
                />
                <div className='data-container'>
                    <h1>Shopping Cart</h1>
                    <div className={'data '+ responsive.class}>
                        <div className='items'>
                            {selectedItems.map((val) => {
                                return <Item key={`${val.id}-${refreshState}`} item={val} action={refreshTotal} remove={removeItem} moneyType={coin.slice(coin.indexOf('/') + 1)} responsive={responsive}/>
                            })}
                        </div>
                        <div className='resume'>
                            <div className='sum'>
                                <div className='subtotal'> 
                                    <span>Subtotal</span>
                                    <span>{coin.slice(coin.indexOf('/') + 1)} {total.toFixed(2)}</span>                            
                                </div>
                                <div className='shipping'> 
                                    <span>Estimated Shipping</span>
                                    <span>{coin.slice(coin.indexOf('/') + 1)} {shipping.toFixed(2)}</span>                            
                                </div>
                                <div className='total'> 
                                    <span>Total</span>
                                    <span>{coin.slice(coin.indexOf('/') + 1)} {(total+shipping).toFixed(2)}</span>                            
                                </div>
                            </div> 
                            <div className='checkout'>
                                <button onClick={goToCheckout}>Proceed to checkout</button>
                                <div className='error-message'>
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Cart;
