import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { AiFillCheckCircle } from 'react-icons/ai/';


function VerifyPayment(){
    let history = useHistory();
    const [coin] = useState(() => 
        JSON.parse(localStorage.getItem('coin'))
    );
    const [total] = useState(() => {
        let total = JSON.parse(localStorage.getItem("total"))
        return total || 0;
    });
    const [selectedItems] = useState(() => {
        let items = JSON.parse(localStorage.getItem("selectedItems"))
        return items || [];
    });
    const [shipping] = useState(() => {
        let ship = JSON.parse(localStorage.getItem("shipping"))
        return ship || 0;
    });

    const [width, setWindowWidth] = useState(0);

    const responsive = {
        mobile: width < 1023,
        class: width < 1023 ? 'mobile' : 'pc'
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const restart = () => {
        history.push('/');
        localStorage.setItem("restart",JSON.stringify(true));
    }

    useEffect(() => {
        updateDimensions();

        window.addEventListener("resize", updateDimensions);

    },[]);
    
    return (
        <div className='container'>
            <div className='data-container'>
                <h1>Payment</h1>
                <div className={'data '+ responsive.class}>
                    <div className='verify'>
                        <AiFillCheckCircle size={"130px"} color={"#78D74B"}/>
                        <span className='bold'>Payment successful</span>
                        <span>Thank you for your order!</span>
                    </div>
                    <div className='resume'>
                        <div className='details'>
                            <div className='summary'> 
                                <h3>Order summary</h3>
                                {selectedItems.map((val) => {
                                    return (
                                        <div className='item-resume'>
                                            <span className='qnt'>{val.quantity}x</span>
                                            <span className='name'><b>{val.name}</b></span>
                                            <span className='price-resume'>{coin} {val.price.toFixed(2)}</span>    
                                        </div>
                                    )
                                })}
                            </div> 
                            <div className='customer'> 
                                <h3>Customer details</h3>
                                <span>Alicia Schmidt</span>
                                <span>alishc@gmail.com</span>                            
                            </div>
                            <div className='subtotal'> 
                                    <span>Subtotal</span>
                                    <span>{coin} {total.toFixed(2)}</span>                            
                            </div>
                            <div className='shipping'> 
                                <span>Estimated Shipping</span>
                                <span>{coin} {shipping.toFixed(2)}</span>                            
                            </div>
                            <div className='total'> 
                                <span>Total</span>
                                <span>{coin} {(total+shipping).toFixed(2)}</span>                            
                            </div>
                        </div>
                        <div className='checkout'>
                            <button onClick={restart}>Restart</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyPayment;